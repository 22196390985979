/*---------------------
  Contact
-----------------------*/
.contact {
  padding-bottom: 60px;
}

.contact__map {
  height: 460px;
  box-shadow: 0px 3px 15px rgba(0, 59, 85, 0.1);
  margin-bottom: 80px;

  iframe {
    width: 100%;
  }
}

.contact__text {
  margin-bottom: 40px;

  .section-title {
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 45px;
  }

  ul {

    li {
      list-style: none;
      margin-bottom: 55px;
      overflow: hidden;

      &:last-child {
        margin-bottom: 0;
      }

      .contact__widget__icon {
        float: left;
        margin-right: 30px;

        span {
          font-size: 36px;
          color: $primary-color;
        }
      }

      .contact__widget__text {
        overflow: hidden;

        h6 {
          font-size: 15px;
          color: #5e6164;
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 14px;
        }

        p {
          font-size: 24px;
          color: $secondary-color;
          margin: 0;
        }
      }
    }
  }
}

.contact__form {
  margin-bottom: 40px;

  .section-title {
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 45px;
  }

  form {

    input {
      width: 100%;
      height: 50px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 15px;
      color: #5e6164;
      padding-left: 20px;
      margin-bottom: 30px;

      &::placeholder {
        color: #5e6164;
      }
    }

    textarea {
      width: 100%;
      height: 120px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 15px;
      color: #5e6164;
      padding-left: 20px;
      padding-top: 12px;
      resize: none;
      margin-bottom: 24px;

      &::placeholder {
        color: #5e6164;
      }
    }
  }
}