@import "variable";
@import "mixins";

@import "base";
@import "header";
@import "hero";
@import "home-page";
@import "footer";
@import "about";
@import "breadcrumb";
@import "services";
@import "services-details";
@import "blog";
@import "blog-details";
@import "contact";
@import "responsive";
@import "nav";

.template-bg-secondary {
    background-color: $secondary-color;
    h1, h2, h3, h4, h5, h6, p {
        color: $white-color
    }
}

.template-color-primary {
    color: $primary-color !important
}

.template-color-secondary {
    color: $secondary-color !important
}

.article-card {
    position: relative;
    height: 432px;

    img {
        width: 100%
    }

    .article-info {
        position: absolute;
        bottom: 0;
        height: 105px;
        transition: ease-in-out 0.5s;
        width: 100%;

        .article-subtitle {
            visibility: hidden;
            opacity: 0; 
            transition: ease-in-out 0.7s;
        }

        a {
            visibility: hidden;
            opacity: 0; 
            transition: ease-in 0.7s 0.2s;
            transition: ease-out 0.1s;
        }
    }

    &:hover > .article-info {
        height: 250px;

        .article-subtitle {
            visibility: visible;
            opacity: 1;
        }

        a {
            visibility: visible;
            opacity: 1;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}