.nav_menu {
	padding-top: 32px !important;
	padding-bottom: 32px !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.nav_menu ul {
	margin-bottom: 0;
}

.nav_menu ul li {
	list-style: none;
	display: inline-block;
	margin-right: 40px;
	position: relative;
}

.nav_menu ul li a.active {
	color: $primary-color;
}

.nav_menu ul li:hover a {
	color: $primary-color;
}

.nav_menu ul li:hover .dropdown {
	top: 30px;
	opacity: 1;
	visibility: visible;
}

.nav_menu ul li:last-child {
	margin-right: 0;
}

.nav_menu ul li .dropdown {
	position: absolute;
	left: 0;
	top: 56px;
	width: 150px;
	background: #111111;
	text-align: left;
	padding: 5px 0;
	z-index: 9;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all, 0.3s;
	-o-transition: all, 0.3s;
	transition: all, 0.3s;
}

.nav_menu ul li .dropdown li {
	display: block;
	margin-right: 0;
}

.nav_menu ul li .dropdown li a {
	font-size: 14px;
	color: #ffffff;
	font-weight: 400;
	padding: 5px 20px;
	text-transform: capitalize;
}

.nav_menu ul li .dropdown li a:after {
	display: none;
}

.nav_menu ul li a {
	font-size: 18px;
	font-family: "Oswald", sans-serif;
	color: #03123b;
	display: block;
	font-weight: 600;
	padding: 3px 0;
	text-transform: uppercase;
	-webkit-transition: all, 0.3s;
	-o-transition: all, 0.3s;
	transition: all, 0.3s;
	text-decoration: none;
}

@media only screen and (max-width: 479px) {
    .nav_menu ul li {
        width: 100%;
    }
}

.nav_menu_alt {
	padding-top: 32px !important;
	padding-bottom: 32px !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.nav_menu_alt ul {
	margin-bottom: 0;
}

.nav_menu_alt ul li {
	list-style: none;
	display: inline-block;
	margin-right: 40px;
	position: relative;
}

.nav_menu_alt ul li a.active {
	color: $primary-color;
}

.nav_menu_alt ul li:hover a {
	color: $primary-color;
}

.nav_menu_alt ul li:hover .dropdown {
	top: 30px;
	opacity: 1;
	visibility: visible;
}

.nav_menu_alt ul li:last-child {
	margin-right: 0;
}

.nav_menu_alt ul li .dropdown {
	position: absolute;
	left: 0;
	top: 56px;
	width: 150px;
	background: #111111;
	text-align: left;
	padding: 5px 0;
	z-index: 9;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all, 0.3s;
	-o-transition: all, 0.3s;
	transition: all, 0.3s;
}

.nav_menu_alt ul li .dropdown li {
	display: block;
	margin-right: 0;
}

.nav_menu_alt ul li .dropdown li a {
	font-size: 14px;
	color: #ffffff;
	font-weight: 400;
	padding: 5px 20px;
	text-transform: capitalize;
}

.nav_menu_alt ul li .dropdown li a:after {
	display: none;
}

.nav_menu_alt ul li a {
	font-size: 18px;
	font-family: "Oswald", sans-serif;
	color: $white-color;
	display: block;
	font-weight: 600;
	padding: 3px 0;
	text-transform: uppercase;
	-webkit-transition: all, 0.3s;
	-o-transition: all, 0.3s;
	transition: all, 0.3s;
	text-decoration: none;
}

@media only screen and (max-width: 479px) {
    .nav_menu_alt ul li {
        width: 100%;
    }
}