/*---------------------
  Chooseus
-----------------------*/
.chooseus {
  background: $secondary-color;
  position: relative;
  padding-bottom: 49px;
  padding-top: 90px;
}

.chooseus__pic {
  position: absolute;
  right: 0;
  top: 0;
  height: 565px;
  width: 50%;
  margin-top: -50px;
}

.chooseus__text {

  .section-title {

    h2 {
      color: $white-color;
    }
  }
}

.chooseus__item {
  margin-bottom: 45px;
}

.chooseus__item__icon {
  float: left;
  margin-right: 20px;
}

.chooseus__item__text {
  overflow: hidden;

  h5 {
    color: $white-color;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  p {
    color: $white-color;
    opacity: .9;
    margin-bottom: 0;
  }
}

/*---------------------
  Projects
-----------------------*/
.projects__all {
  text-align: right;
  margin-bottom: 45px;
}

.projects__slider {

  &.owl-carousel {

    .col-lg-4 {
      max-width: 100%;
    }

    .owl-dots {
      text-align: center;
      padding-top: 45px;

      button {
        height: 10px;
        width: 10px;
        background: #e6eefb;
        border-radius: 50%;
        margin-right: 10px;

        &.active {
          background: $primary-color;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.projects__item {
  overflow: hidden;
}

.projects__item__pic {

  img {
    min-width: 100%;
  }
}

.projects__item__text {
  background: $secondary-color;
  padding: 35px 30px 25px;
  position: relative;

  h5 {
    padding: 12px 30px 10px 40px;
    background: $primary-color;
    display: inline-block;
    -webkit-transform: skew(-32deg);
    transform: skew(-32deg);
    position: absolute;
    left: -14px;
    top: -23px;

    span {
      color: $white-color;
      text-transform: uppercase;
      -webkit-transform: skew(32deg);
      transform: skew(32deg);
      display: block;
    }
  }

  p {
    color: $white-color;
    margin-bottom: 0;
  }
}

/*---------------------
  Testimonial
-----------------------*/
.testimonial {
  padding-bottom: 60px;
}

.testimonial__text {
  margin-bottom: 40px;

  .section-title {

    h2 {
      color: $white-color;
    }
  }
}

.testimonial__slider {

  &.owl-carousel {

    img {
      display: inline-block;
      width: auto;
    }

    .owl-nav {
      position: absolute;
      right: 0;
      bottom: 38px;

      button {
        color: #bfbfbf;
        font-size: 36px;
        line-height: 36px;

        &.owl-next {
          margin-left: 20px;
        }
      }
    }
  }
}

.testimonial__item {

  p {
    color: $white-color;
    line-height: 30px;
    margin-bottom: 35px;
    margin-top: 20px;
  }
}

.testimonial__item__author {
  overflow: hidden;
}

.testimonial__item__author__pic {
  float: left;
  margin-right: 30px;
}

.testimonial__item__author__text {
  overflow: hidden;
  padding-top: 14px;

  h5 {
    color: $white-color;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 12px;
  }

  span {
    font-size: 14px;
    color: $white-color;
    display: block;
    text-transform: uppercase;
  }
}

.request__form {
  margin-bottom: 40px;

  .section-title {

    h2 {
      color: $white-color;
    }
  }

  form {

    input {
      width: 100%;
      height: 50px;
      border: 1px solid #515768;
      background: rgba(0, 0, 0, 0.3);
      margin-bottom: 30px;
      font-size: 14px;
      color: #7d8184;
      padding-left: 20px;
      @include transition(all, .3s);

      &:focus {

        &::placeholder {
          color: $white-color;
        }
      }

      &::placeholder {
        color: #7d8184;
      }
    }

    .nice-select {
      float: none;
      display: block;
      height: 50px;
      border: 1px solid #515768;
      background: rgba(0, 0, 0, 0.3);
      margin-bottom: 30px;
      padding-left: 20px;
      border-radius: 0;
      line-height: 50px;
      @include transition(all, .3s);

      &:after {
        border-bottom: 2px solid $white-color;
        border-right: 2px solid $white-color;
        height: 8px;
        right: 22px;
        width: 8px;
      }

      &:focus {

        span {
          color: $white-color;
        }
      }

      span {
        font-size: 14px;
        color: #7d8184;
      }

      .list {
        margin-top: 0;
        border-radius: 0;
        width: 100%;
      }
    }

    textarea {
      width: 100%;
      height: 100px;
      border: 1px solid #515768;
      background: rgba(0, 0, 0, 0.3);
      margin-bottom: 34px;
      font-size: 14px;
      color: #7d8184;
      padding-left: 20px;
      padding-top: 12px;
      resize: none;
      @include transition(all, .3s);

      &:focus {

        &::placeholder {
          color: $white-color;
        }
      }

      &::placeholder {
        color: #7d8184;
      }
    }

    .site-btn {
      margin-left: 15px;
    }
  }
}

/*---------------------
  Latest
-----------------------*/
.latest {
  padding-bottom: 70px;
}

.latest__item {
  margin-bottom: 30px;
}

.latest__item__pic {
  height: 370px;
  position: relative;
  box-shadow: 5px 5px 15px rgba(6, 14, 34, 0.15);
  ;
}

.latest__item__pic__inner {
  position: absolute;
  left: 0;
  bottom: 25px;
  width: 100%;
  padding: 0 70px 0 30px;

  .label {
    background: $primary-color;
    padding: 2px 10px 1px 10px;
    -webkit-transform: skew(-32deg);
    transform: skew(-32deg);
    display: inline-block;

    span {
      font-size: 11px;
      color: $white-color;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
      -webkit-transform: skew(30deg);
      transform: skew(30deg);
      display: block;
    }
  }

  h5 {
    color: $white-color;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
    margin-top: 12px;
  }
}

.latest__item__text {
  padding-top: 22px;
  padding-left: 30px;
  padding-right: 35px;

  ul {
    margin-bottom: 10px;

    li {
      list-style: none;
      display: inline-block;
      font-size: 13px;
      color: #888888;
      margin-right: 28px;
      position: relative;

      &:after {
        position: absolute;
        right: -16px;
        top: 0;
        content: "|";
      }

      &:last-child {
        margin-right: 0;

        &:after {
          display: none;
        }
      }

      span {
        color: $primary-color;
      }
    }
  }

  p {
    margin-bottom: 18px;
  }

  a {
    font-size: 15px;
    font-family: 'Oswald', sans-serif;
    color: #404040;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 0 10px;
    position: relative;
    z-index: 1;

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50%;
      width: 100%;
      background: $primary-color;
      content: "";
      opacity: .4;
      z-index: -1;
    }
  }
}