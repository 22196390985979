$primary-color: #ff5f2a;
$secondary-color: #03123b;
$white-color: #ffffff;
$heading-color: #111111;
$para-color: #5C5C5C;
$black-color: #000000;
$heading-color-2: #323232;
$normal-color: #1c1c1c;
$background: #f5f5f5;
$background-2: #f2f2f2;
$border: #ebebeb;
$border-1: #e1e1e1;

$fm-unna: 'Unna', serif;
$fm-nunito: 'Nunito Sans', sans-serif;