/*---------------------
  Breadcrumb
-----------------------*/
.breadcrumb-option {
    height: 400px;
    display: flex;
    align-items: center;
}

.breadcrumb__text {

    h2 {
        font-size: 48px;
        font-weight: 700;
        color: $white-color;
        text-transform: uppercase;
    }
}