/*---------------------
    Blog
-----------------------*/
.blog__item {
    margin-bottom: 60px;

    &.extra__large__item {
        
        .blog__item__pic {
            height: 600px;
        }
    }

    &.small__large {
        
        .blog__item__pic {
            height: 400px;
        }
    }

    &.large__item {
        
        .blog__item__pic {
            height: 460px;
        }
    }

    &.mid__large {
        
        .blog__item__pic {
            height: 445px;
        }
    }

    &.m__large {
        
        .blog__item__pic {
            height: 430px;
        }
    }

    &.n__large {
        
        .blog__item__pic {
            height: 420px;
        }
    }
}

.blog__item__pic {
    height: 370px;
    position: relative;
    box-shadow: 5px 5px 15px rgba(6, 14, 34, 0.15);;
  }
  
  .blog__item__pic__inner {
    position: absolute;
    left: 0;
    bottom: 25px;
    width: 100%;
    padding: 0 70px 0 30px;
  
    .label {
      background: $primary-color;
      padding: 2px 10px 1px 10px;
      -webkit-transform: skew(-32deg);
      transform: skew(-32deg);
      display: inline-block;
  
      span {
        font-size: 11px;
        color: $white-color;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        -webkit-transform: skew(30deg);
        transform: skew(30deg);
        display: block;
      }
    }
  
    h5 {
      color: $white-color;
      font-weight: 600;
      line-height: 30px;
      text-transform: uppercase;
      margin-top: 12px;
    }
  }
  
  .blog__item__text {
    padding-top: 22px;
    padding-left: 30px;
    padding-right: 35px;
  
    ul {
      margin-bottom: 10px;
  
      li {
        list-style: none;
        display: inline-block;
        font-size: 13px;
        color: #888888;
        margin-right: 28px;
        position: relative;

        &:after {
            position: absolute;
            right: -16px;
            top: 0;        
            content: "|";
        }
  
        &:last-child {
          margin-right: 0;

          &:after {
              display: none;
          }
        }
  
        span {
          color: $primary-color;
        }
      }
    }
  
    p {
      margin-bottom: 18px;
    }
  
    a {
      font-size: 15px;
      font-family: 'Oswald', sans-serif;
      color: #404040;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
      padding: 0 10px;
      position: relative;
      z-index: 1;
  
      &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 50%;
        width: 100%;
        background: $primary-color;
        content: "";
        opacity: .4;
        z-index: -1;
      }
    }
  }